import uaNum from "./uaJudgment.js";

/**
 * @constructor
 */
class SdgsAct {
    constructor() {
        this.$activitySelectImg = $('.p-sdgs__activitySelectImg');
        this.$activitySelectDiv = $('.p-sdgs__activitySelectDiv');
        this.$planContClose = $('.p-sdgs__planContClose');
        this.$planCont = $('.p-sdgs__planCont');
        this.$planContBg = $('.p-sdgs__planContBg');
        this.$body = $('body');
        this.$window = $(window);
        this.scrollTop;
    }

    init() {
        this.mouseClickAct();
    }

    mouseClickAct() {
        this.$activitySelectImg.on('click', (e) => {
            e.stopPropagation(); // clickイベントを親にいかせない

            // 地球上のアイコンの制御
            this.$activitySelectImg.find('img').removeClass('is-current');
            this.$activitySelectImg.find('img').addClass('is-exclusion');
            $(e.target).addClass('is-current');

            // クリックしたliの順番を取得し、連動してコンテンツを表示
            let contNum = $(e.target).parents('li').index();
            contNum = contNum + 1;
            this.$planCont.hide();
            $('.p-sdgs__planCont--0' + contNum).show();
            this.$planContClose.show();

            if (uaNum == 1) { //spだった場合のみ黒背景を出す
                this.bodyFixedOn();
                this.$planContBg.show();
            }
        });
        this.$activitySelectDiv.on('click', () => {
            this.$activitySelectImg.find('img').removeClass('is-current is-exclusion');
            this.$planCont.hide();
            this.$planContBg.hide();
        });
        this.$planContClose.on('click', () => {
            this.$activitySelectImg.find('img').removeClass('is-current is-exclusion');
            this.$planCont.hide();
            this.$planContBg.hide();
            this.$planContClose.hide();
            if (uaNum == 1) { //spだった場合、背景固定を解除
                this.bodyFixedOff();
            }
        });
    }
    mouseOverAct() {
        this.$activitySelectImg.mouseover(e => {
            this.$activitySelectImg.find('img').addClass('is-exclusion');
            $(e.target).addClass('is-current');
        }).mouseout(e => {
            this.$activitySelectImg.find('img').removeClass('is-exclusion');
            $(e.target).removeClass('is-current');
        });
    }
    bodyFixedOn() {
        this.scrollTop = $(window).scrollTop();
        this.$body.css({
            position: 'fixed',
            top: -(this.scrollTop)
        });
    }
    bodyFixedOff() {
        this.$body.css({
            position: '',
            top: ''
        });
        $(window).scrollTop(this.scrollTop);
    }
};
export default SdgsAct;
