import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

/**
 * Pagetop
 * @constructor
 */
class Pagetop {
  constructor() {}
  /**
   * Pagetop
   */
  init() {
    var topBtn = $('#pagetop');
    topBtn.hide();
    $(window).scroll(function () {
      var scrollHeight = $(document).height();
      var scrollPosition = $(window).height() + $(window).scrollTop();
      var footHeight = $(".l-footer").innerHeight() + 25;
      if (scrollHeight - scrollPosition <= footHeight) {
        $(".page_top").css({ position: "absolute", bottom: footHeight + 0 , 'z-index':'5'});
      } else {
        $(".page_top").css({ position: "fixed", bottom: "24px" });
      }
      
      if ($(this).scrollTop() > 200) {
        topBtn.fadeIn();
      } else {
        topBtn.fadeOut();
      }
    });
    topBtn.click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 500);
      return false;
    });
    
    const TopHeader = new TimelineMax();
    new ScrollMagic.Scene({
      triggerElement: '.p-top__business',
      triggerHook: 'onCenter',
      offset: '0',
      duration: '0',
      reverse: true,
    })
    .setTween(TopHeader)
//    .addIndicators({name: "TopHeader"})
    .setClassToggle('.l-header', 'scroll')
    .addTo(controller);
    
    $('.l-header__btn').on('click',function() {
      $(this).toggleClass('is-active');
      $(this).next().slideToggle();
      if($(this).hasClass('is-active')) {
        $('body').css('position','fixed')
      } else {
        $('body').css('position','relative')
      }
    })
    

    /* pagetop */
//    const pagetop = new TimelineMax();
//    new ScrollMagic.Scene({
//        triggerElement: '.l-footer',
//        triggerHook: 'onEnter',
//        offset: 0,
//        duration: 0,
//        reverse: true,
//      })
//      .setTween(pagetop)
//      .setClassToggle('.pagetop', 'abso')
////    .addIndicators({name: 'pagetop'})
//      .addTo(controller);
  }
};

export default Pagetop;
