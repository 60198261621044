import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

/**
 * TopHeader
 * @constructor
 */
class TopHeader {
  constructor() {}
  /**
   * TopHeader
   */
  init() {

  }
};
export default TopHeader;
